import './services.scss';
import { Slide, Fade } from 'react-reveal';

const Services = () => {
    return (
        <section className="services">
            <Slide bottom>
                <div className='services-card' id="about">
                    <h2 className='services-title'>Как сделать заказ ?</h2>
                    <p className='services-description'>Процесс заказа очень прост и будет обработан в течении рабочего дня.
                    </p>
                    <div className='features-list'>
                        <Slide right>
                            <div className='feature'>
                                <span className='feature-left'>
                                    <p className='feature-no'>01</p>
                                    <p className='feature-title'>Оставить заявку</p>
                                </span>
                                <p className='feature-desc'>Вам необходимо заполнить форму обратной связи, которая находится ниже.</p>
                            </div>
                        </Slide>

                        <Slide right>

                            <div className='feature'>
                                <span className='feature-left'>
                                    <p className='feature-no'>02</p>
                                    <p className='feature-title'>Мы свяжемся с вами</p>
                                </span>
                                <p className='feature-desc'>Наш менеджер в ближайшее время связывается с вами для уточнения деталей.</p>
                            </div>
                        </Slide>

                        <Slide right>
                            <div className='feature'>
                                <span className='feature-left'>
                                    <p className='feature-no'>03</p>
                                    <p className='feature-title'>Создание заказа</p>
                                </span>
                                <p className='feature-desc'>Мы с вами обсуждаем детали заказа,способ оплаты и сроки доставки.</p>
                            </div>
                        </Slide>

                        <Slide right>
                            <div className='feature'>
                                <span className='feature-left'>
                                    <p className='feature-no'>04</p>
                                    <p className='feature-title'>Доставка</p>
                                </span>
                                <p className='feature-desc'>Доставим ваш заказ в кротчайшие сроки.</p>
                            </div>
                        </Slide>
                    </div>
                </div>
            </Slide>
            <Fade>
                <div className='features-text'>
                    <h3 className='cards-title'>Почему выбирают нас ?</h3>
                    <p className='cards-description'>Приципы компании благодаря которым наши клиенты всегда остаются довольны.</p>
                </div>
                <div className="feature-cards">
                    <div className="feature-card">
                        <p className='card-title'>Просто</p>
                        <p className='card-copy'>
                             Процесс заказа происходит в максимально короткие сроки в сопровождении вашего менеджера который всегда ответит на любые ваши вопросы.
                        </p>
                    </div>
                    <div className="feature-card">
                        <p className='card-title'>Качественно</p>
                        <p className='card-copy'>
                            Мы поставляем сертифицированный инструмент лучших производителей европейского стандарта
                        </p>
                    </div>
                    <div className="feature-card">
                        <p className='card-title'>Быстро</p>
                        <p className='card-copy'>
                            У нас осуществляется постоянный поток поставок, поэтому ваш товар будет доставлен в кратчайшие сроки.
                        </p>
                    </div>
                </div>
            </Fade>
        </section>
    );
}

export default Services;