import './careers.scss';
import { useEffect } from 'react';
import { Fade } from 'react-reveal';
const Careers = () => {

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [])

    return (
        <>
            <section className="careers">
                <Fade right>
                </Fade>
                <div className='careers-content'>
                    <Fade left>
                        <div className="career-copy">
                            <h2 className='careers-title'>Your form<br />Submitted</h2>
                            <h4 className='careers-subtitle'>We believe that we can grow faster together.</h4>
                            <p className='form-info'>Enter your information and we will reach out to you.</p>
                        </div>
                    </Fade>
                </div>
            </section>
        </>
    );
}

export default Careers;