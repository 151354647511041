import {BrowserRouter} from 'react-router-dom';
import {Route, Routes} from 'react-router';
import Home from './views/Home';
import Nav from './components/nav/Nav';
import Footer from './components/footer/Footer';
import Careers from './views/careers/Careers';

function App() {
    return (
        <BrowserRouter>
            <Nav/>
            <Routes>
                <Route path="submitted" element={<Careers/>} ></Route>
                <Route path="/" element={<Home/>}></Route>
            </Routes>
            <Footer/>
        </BrowserRouter>
    );
}

export default App;
