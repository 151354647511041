import './form.css';

const Form = () => {
    return (
        <div class="wrapper" id="form">
            <div class="contacts">
                <h2>Наши контакты</h2>
                <div className="direct-contact-container">
                    <ul className="contact-list">
                        <li className="list-item"><i className="fa fa-map-marker fa-2x"><span
                            className="contact-text place">City, State</span></i></li>

                        <li className="list-item"><i className="fa fa-phone fa-2x"><span className="contact-text phone"><a
                            href="tel:1-212-555-5555" title="Give me a call">(212) 555-2368</a></span></i></li>

                        <li className="list-item"><i className="fa fa-envelope fa-2x"><span
                            className="contact-text gmail"><a href="mailto:#"
                                                              title="Send me an email">hitmeup@gmail.com</a></span></i>
                        </li>

                    </ul>
                </div>
            </div>
            <div>
                <form id="lead-form" action="https://webto.salesforce.com/servlet/servlet.WebToLead?encoding=UTF-8" method="POST">
                    <input type="hidden" name="oid" value="00D5i000009Lpnj"/>
                        <input type="hidden" name="retURL" value="http://u133299.test-handyhost.ru/submitted"/>
                    <label htmlFor="first_name">Ваше имя</label><input id="first_name" maxLength="40"
                                                                         name="first_name" size="20" type="text"/>
                    <label htmlFor="email">Email</label><input id="email" maxLength="80" name="email" size="20"
                                                               type="text"/>

                    <label htmlFor="phone">Телефон</label><input id="phone" maxLength="40" name="phone" size="20"
                                                               type="text"/>

                    <label htmlFor="description">Сообщение</label><textarea name="description"></textarea>

                <button type="submit" name="submit">ОТПРАВИТЬ</button>
            </form>
            </div>

        </div>


)
}
export default Form;