import './hero.scss';
import { Fade } from 'react-reveal';

const Hero = () => {
    return (
        <section id="hero" className="hero">
            <div className='hero-content'>
                <Fade bottom cascade distance={'50px'}>
                    <section>
                        <h1 className='headline'>Профессиональный инструмент<br/>для ваших лучших решений.</h1>
                        <p className='sub-headline'>Инструмент от мировых брендов под любые ваши запросы.</p>
                    </section>
                </Fade>
            </div>
        </section>
    );
}

export default Hero;