import { useEffect } from 'react';
import Hero from '../components/hero/Hero';
import Services from '../components/services/Services';
import Form from '../components/form/Form';
import Testimonials from '../components/testimonials/Testimonials';

const Home = () => {

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [])

    return (
        <>
            <Hero />
            <Services />
            <Testimonials />
            <Form />
        </>
    );
}

export default Home;