import './nav.scss';
import logo from '../../assets/svg/logo_pink.svg';
import {useEffect, useRef, useState} from 'react';
import {Fade} from 'react-reveal';
import {Link} from 'react-scroll';
import {useLocation} from 'react-router';
import {Link as RouterLink} from 'react-router-dom';

const Nav = () => {

    const [scroll, setScroll] = useState(true)
    const [loc, setLoc] = useState('');
    const location = useLocation();
    const menuRef = useRef(null);
    const navRef = useRef(null);

    useEffect(() => {
        document.addEventListener("scroll", () => {
            const scrollCheck = window.scrollY < 50
            if (scrollCheck !== scroll) {
                setScroll(scrollCheck)
            }
        })
    })

    useEffect(() => {
        setLoc(location.pathname);
    }, [loc, location])

    const updateMenuButton = () => {
        menuRef.current.classList.toggle('is-active');
        navRef.current.classList.toggle('mobile-nav-active');

        menuRef.current.classList[1] === 'is-active'
            ? document.querySelector('body').style.overflowY = 'hidden'
            : document.querySelector('body').style.overflowY = 'scroll'
    }

    const hideMenu = () => {
        menuRef.current.classList.remove('is-active')
        navRef.current.classList.remove('mobile-nav-active');
        document.querySelector('body').style.overflowY = 'scroll'
    }

    return (
        <>
            <Fade top>
                <header className={scroll ? '' : 'header-small'}>
                    <nav>
                        <div className='nav-main'>
                            <Link onClick={() => hideMenu()} to="hero"><img className='nav-logo' src={logo} alt="logo"/></Link>

                            {loc === '/submitted' &&
                            <>
                                <RouterLink className='nav-item' to="/">На главную</RouterLink>
                            </>
                            }
                            {loc === '/' &&
                            <>
                                <Link className='nav-item' to="about">О нас</Link>
                            </>
                            }
                        </div>

                        <div className='nav-secondary'>
                            {loc === '/' &&
                            <>
                                <Link to="form" className='nav-item'>Свяжитесь с нами</Link>
                            </>
                            }
                            <div onClick={() => updateMenuButton()} className="menu-button">
                                <div className="menu-icon" ref={menuRef}>
                                    <span></span>
                                    <span></span>
                                    <span></span>
                                    <span></span>
                                </div>
                            </div>
                        </div>
                    </nav>
                </header>
            </Fade>
            <nav className='mobile-nav' ref={navRef}>
                <div className='nav-links-mobile'>
                    {loc === '/submitted'
                        ?
                        <>
                            <RouterLink onClick={() => updateMenuButton()} className='mobile-nav-item'
                                        to="/">Home</RouterLink>
                        </>
                        :
                        <>
                            <Link onClick={() => updateMenuButton()} className='mobile-nav-item' to="about">About</Link>
                            <Link to="form" onClick={() => updateMenuButton()} className='mobile-nav-button'>Contact
                                Us</Link>
                        </>
                    }
                </div>
            </nav>
        </>
    );
}

export default Nav;
